import { InitOptions } from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
const { i18next, i18nDefaultOptions, i18nLangDetectionConfig } = require("@sundae/i18n-config");

export const languages = [
  {
    locale: "en",
    localeString: "English",
  },
];

const options: InitOptions = {
  ...i18nDefaultOptions(languages),
  detection: i18nLangDetectionConfig,
  ns: ["common", "glossary", "header", "not-found", "rewards", "wallet", "warnings"],
  defaultNS: "common",
};

i18next
  // setup your `i18n` to load the proper files
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`./translations/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((_error) => {
          callback(_error, null);
        });
    })
  )
  .init(options);

export default i18next;
